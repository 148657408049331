import { all } from 'redux-saga/effects';

import agency from './agency/saga';
import auth from './auth/saga';
import category from './category/saga';
import comment from './comment/saga';
import dataField from './dataField/saga';
import dataGroup from './dataGroup/saga';
import discount from './discount/saga';
import feedback from './feedback/saga';
import rating from './rating/saga';
import media from './media/saga';
import member from './member/saga';
import menuItem from './menuItem/saga';
import order from './order/saga';
import permission from './permission/saga';
import post from './post/saga';
import role from './role/saga';
import seo from './seo/saga';
import settings from './settings/saga';
import slider from './slider/saga';
import postType from './type/saga';
import user from './user/saga';
import warehouse from './warehouse/saga';
import widgets from './widget/saga';
import kiotvietPrice from './kiotviet/price/saga';
import kiotvietCategory from './kiotviet/category/saga';
import kiotvietProduct from './kiotviet/product/saga';

export default function* rootSaga() {
  yield all([
    agency(),
    auth(),
    category(),
    comment(),
    dataField(),
    dataGroup(),
    discount(),
    feedback(),
    rating(),
    media(),
    member(),
    menuItem(),
    order(),
    permission(),
    post(),
    role(),
    seo(),
    settings(),
    slider(),
    postType(),
    user(),
    warehouse(),
    widgets(),
    kiotvietPrice(),
    kiotvietCategory(),
    kiotvietProduct(),
  ]);
}
