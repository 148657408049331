/* eslint-disable import/no-extraneous-dependencies */
import { call, put, takeLatest } from 'redux-saga/effects';

import { axiosMicro } from '~/services';
import * as Actions from './actions';

function* getAllKiotvietProducts({ payload }) {
  try {
    const response = yield call(
      typeof payload === 'string'
        ? () => axiosMicro.get(`/kiotviet/products?${payload}`)
        : () => axiosMicro.get('/kiotviet/products', { params: payload }),
    );
    yield put(Actions.getAllKiotvietProductsSuccess(response.data));
  } catch (error) {
    if (error?.response?.data) {
      const messages = error.response.data;
      yield put(Actions.getAllKiotvietProductsFailure(messages));
    }
  }
}

function* createKiotvietProduct({ payload }) {
  try {
    const response = yield call(() => axiosMicro.post('/kiotviet/products', payload));
    yield put(Actions.createKiotvietProductSuccess(response.data));
  } catch (error) {
    if (error?.response?.data) {
      const messages = error.response.data;
      yield put(Actions.createKiotvietProductFailure(messages));
    }
  }
}

function* updateKiotvietProduct({ payload }) {
  try {
    const response = yield call(() => axiosMicro.patch(`/kiotviet/products/${payload.id}`, payload.body));
    yield put(Actions.updateKiotvietProductSuccess(response.data));
  } catch (error) {
    if (error?.response?.data) {
      const messages = error.response.data;
      yield put(Actions.updateKiotvietProductFailure(messages));
    }
  }
}

function* deleteKiotvietProduct({ payload }) {
  try {
    yield call(() => axiosMicro.delete(`/kiotviet/products/${payload}`));
    yield put(Actions.deleteKiotvietProductSuccess(payload));
  } catch (error) {
    if (error?.response?.data) {
      const messages = error.response.data;
      yield put(Actions.deleteKiotvietProductFailure(messages));
    }
  }
}

// eslint-disable-next-line func-names
export default function* () {
  yield takeLatest(Actions.getAllKiotvietProductsRequest, getAllKiotvietProducts);
  yield takeLatest(Actions.createKiotvietProductRequest, createKiotvietProduct);
  yield takeLatest(Actions.updateKiotvietProductRequest, updateKiotvietProduct);
  yield takeLatest(Actions.deleteKiotvietProductRequest, deleteKiotvietProduct);
}
