// eslint-disable-next-line import/no-extraneous-dependencies
import { createAction } from 'redux-actions';

export const getAllKiotvietProductsRequest = createAction('GET_ALL_KIOTVIET_PRODUCTS_REQUEST');
export const getAllKiotvietProductsSuccess = createAction('GET_ALL_KIOTVIET_PRODUCTS_SUCCESS');
export const getAllKiotvietProductsFailure = createAction('GET_ALL_KIOTVIET_PRODUCTS_FAILURE');

export const createKiotvietProductRequest = createAction('CREATE_KIOTVIET_PRODUCT_REQUEST');
export const createKiotvietProductSuccess = createAction('CREATE_KIOTVIET_PRODUCT_SUCCESS');
export const createKiotvietProductFailure = createAction('CREATE_KIOTVIET_PRODUCT_FAILURE');
export const resetCreateKiotvietProduct = createAction('RESET_CREATE_KIOTVIET_PRODUCT');

export const updateKiotvietProductRequest = createAction('UPDATE_KIOTVIET_PRODUCT_REQUEST');
export const updateKiotvietProductSuccess = createAction('UPDATE_KIOTVIET_PRODUCT_SUCCESS');
export const updateKiotvietProductFailure = createAction('UPDATE_KIOTVIET_PRODUCT_FAILURE');
export const resetUpdateKiotvietProduct = createAction('RESET_UPDATE_KIOTVIET_PRODUCT');

export const deleteKiotvietProductRequest = createAction('DELETE_KIOTVIET_PRODUCT_REQUEST');
export const deleteKiotvietProductSuccess = createAction('DELETE_KIOTVIET_PRODUCT_SUCCESS');
export const deleteKiotvietProductFailure = createAction('DELETE_KIOTVIET_PRODUCT_FAILURE');
export const resetDeleteKiotvietProduct = createAction('RESET_DELETE_KIOTVIET_PRODUCT');

export const resetKiotvietProductState = createAction('RESET_KIOTVIET_PRODUCT_STATE');
