const settings = {};

const general = {
  'admin.settings.general.hetmet': 'Tùy chọn cấu hình - Cài đặt',
  'admin.settings.general.breadcrumb.heading': 'Tùy chọn cấu hình',
  'admin.settings.general.breadcrumb.parent': 'Cài đặt',
  'admin.settings.general.title.email_receive_notification': 'Email nhận thông báo',
  'admin.settings.general.title.smtp-option': 'Tùy chọn smtp',
  'admin.settings.general.title.dynamic-option': 'Tùy chọn khác',
  'admin.settings.general.title.dynamic-option.add': 'Thêm tùy chọn khác',
  'admin.settings.general.title.smtp_email': 'Email',
  'admin.settings.general.title.smtp_password': 'Mật khẩu',
  'admin.settings.general.title.footer_script_code': 'Code tracker',
  'admin.settings.general.title.header_script_code': 'Header include',
  'admin.settings.general.title.default-option': 'Tùy chọn mặc định',
  'admin.settings.general.title.custom-option': 'Tùy chọn cá nhân',
  'admin.settings.general.title.social-option': 'Tùy chọn kết nối mạng xã hội',
  'admin.settings.general.title.script-option': 'Tùy chọn head & tracker',
  'admin.settings.general.title.email-option': 'Tùy chọn email nhận thông báo',
  'admin.settings.general.title.email-option.role': 'Email được ngăn cách bởi dấu phẩy',
  'admin.settings.general.title.copyright-option': 'Tùy chọn bản quyền',
  'admin.settings.general.title.company-option': 'Tùy chọn thông tin công ty',
  'admin.settings.general.title.custom-option.btn-add': 'Thêm tùy chọn cá nhân',
  'admin.settings.general.title.custom-option.placeholder.key': 'Khoá',
  'admin.settings.general.title.custom-option.placeholder.title': 'Tiêu đề',
  'admin.settings.general.title.custom-option.placeholder.value': 'Nội dung',
  'admin.settings.general.title.custom-option.footer': 'Footer code',
  'admin.settings.general.title.custom-option.header_script_code': 'Header code',
  'admin.settings.general.default-option.label.logo-light': 'Ảnh logo light',
  'admin.settings.general.default-option.label.logo-dark': 'Ảnh logo dark',
  'admin.settings.general.default-option.label.site_logo': 'Ảnh logo website',
  'admin.settings.general.default-option.label.site_favicon': 'Ảnh favicon website',
  'admin.settings.general.default-option.label.site_description': 'Mô tả',
  'admin.settings.general.default-option.label.site_email': 'Email',
  'admin.settings.general.default-option.label.site_hotline': 'Hotline',
  'admin.settings.general.default-option.label.site_keyword': 'Từ khóa',
  'admin.settings.general.default-option.placeholder.site_keyword': 'Thêm từ khóa',
  'admin.settings.general.default-option.label.site_name': 'Tên website',
  'admin.settings.general.default-option.label.footer_script_code': 'Script code',
  'admin.settings.general.default-option.btn.upload-image': 'Tải hình ảnh mới',
  'admin.settings.general.title.product-option': 'Tùy chọn sản phẩm',
  'admin.settings.general.product-option.label.code-product': 'Mã sản phẩm',
  'admin.settings.general.product-option.label.code-order': 'Mã hóa đơn',
  'admin.settings.general.product-option.label.unit-money': 'Đơn vị tiền tệ',
  'admin.settings.general.product-option.error-msg.syntax_code_product': 'Không để trống trường này!',
  'admin.settings.general.product-option.error-msg.syntax_code_order_main': 'Không để trống trường này!',
  'admin.settings.general.product-option.error-msg.syntax_code_order_footer': 'Không để trống trường này!',
  'admin.settings.general.product-option.label.available-tags': 'Tag sẵn có',
  'admin.settings.general.noti.create-success.title': 'Thêm tùy chọn cá nhân thành công',
  'admin.settings.general.noti.create-failure.title': 'Thêm tùy chọn cá nhân thất bại',
  'admin.settings.general.noti.create-failure.title-2': 'Khoá của bạn đã tồn tại! Vui lòng nhập khoá khác.',
  'admin.settings.general.noti.edit-success.title': 'Chỉnh sửa tùy chọn cấu hình thành công',
  'admin.settings.general.noti.edit-failure.title': 'Chỉnh sửa tùy chọn cấu hình thất bại',
  'admin.settings.general.noti.delete-success.title': 'Xoá tùy chọn cá nhân thành công',
  'admin.settings.general.noti.delete-failure.title': 'Xoá tùy chọn cá nhân thất bại',
  'admin.settings.general.modal.confirm-save-change.title': 'Xác nhận lưu thay đổi',
  'admin.settings.general.modal.confirm-save-change.sub-title': 'Bạn chắc chắn sẽ thay đổi những thông tin này?',
  'admin.settings.custom.modal.delete.title': 'Xác nhận xoá tuỳ chọn cá nhân này!',
  'admin.settings.custom.modal.delete.description': 'Bạn chắc chắn muốn xoá tuỳ chọn cá nhân này?',
  'admin.settings.custom.modal.create.title': 'Thêm tuỳ chọn cá nhân',
  'admin.settings.custom.modal.create.label.key': 'Khoá',
  'admin.settings.custom.modal.create.label.key.description': 'Khoá chỉ bao gồm kí tự thường và số, không dùng tiếng Việt.',
  'admin.settings.custom.modal.create.label.title': 'Tiêu đề',
  'admin.settings.custom.modal.create.label.value': 'Nội dung',
  'admin.settings.custom.modal.create.error-msg.key.required': 'Vui lòng nhập khoá!',
  'admin.settings.custom.modal.create.error-msg.key.regex': 'Khoá không hợp lệ!',
  'admin.settings.custom.modal.create.error-msg.title.required': 'Vui lòng nhập tiêu đề!',
  'admin.settings.custom.modal.create.error-msg.title.min-2': 'Ít nhất 2 ký tự!',
  'admin.settings.custom.modal.create.error-msg.title.max-100': 'Tối đa 100 ký tự!',
  'admin.settings.custom.modal.create.error-msg.value.required': 'Vui lòng nhập nội dung!',
  'admin.settings.redirect.col-left.label.destination': 'Điểm đích',
  'admin.settings.redirect.col-left.label.source': 'Đường đầu',
  'admin.settings.redirect.col-left.label.code': 'Mã chuyển hướng',
  'admin.settings.error-msg-input.source-require': 'Vui lòng nhập điểm đầu',
  'admin.settings.error-msg-input.destination-require': 'Vui lòng nhập điểm đích',
  'admin.settings.redirect.code': 'Mã chuyển hướng mặc định sẽ là 301 nếu bạn không điền vào',
  'admin.settings.redirect.noti.create-setting-redirect.title.success': 'Tạo điều hướng thành công',
  'admin.settings.redirect.noti.update-setting-redirect.title.success': 'Cập nhật điều hướng thành công',
  'admin.settings.redirect.modal.details.title': 'Chi tiết điều hướng',
  'admin.settings.redirect.modal.update.title': 'Cập nhật điều hướng',
  'admin.settings.redirect.modal.delete.title': 'Xác nhận xóa điều hướng này',
  'admin.settings.redirect.modal.delete.description': 'Bạn chắc chắn sẽ xóa điều hướng này',
  'admin.settings.redirect.hetmet': 'Danh sách điều hướng',
  'admin.settings.redirect.noti.delete-setting-redirect.title.success': 'Xóa điều hướng thành công',
  'admin.settings.redirect.noti.delete-setting-redirect.title.failure': 'Xóa điều hướng thất bại',
};

const custom = {
  'admin.settings.custom.hetmet': 'Tùy chọn cá nhân - Cài đặt',
  'admin.settings.custom.breadcrumb.heading': 'Tùy chọn cá nhân',
  'admin.settings.custom.breadcrumb.parent': 'Cài đặt',
};

const vnAdminSettingsLangLib = {
  ...settings,
  ...general,
  ...custom,
};

export default vnAdminSettingsLangLib;
