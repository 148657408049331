const price = {
  'admin.kiotviet.price.hetmet': 'Điều chỉnh giá',
  'admin.kiotviet.price.breadcrumb.heading': 'Điều chỉnh giá',
  'admin.kiotviet.price.breadcrumb.parent': 'KiotViet',
  'admin.kiotviet.price.btn.add': 'Điều chỉnh giá mới',
  'admin.kiotviet.price.col.name': 'Tên sản phẩm',
  'admin.kiotviet.price.col.price': 'Giá điều chỉnh',
  'admin.kiotviet.price.col.type': 'Loại',
  'admin.kiotviet.price.noti.create-price.success': 'Thêm điều chỉnh giá mới thành công!',
  'admin.kiotviet.price.noti.create-price.failure': 'Thêm điều chỉnh giá mới thất bại! Vui lòng thử lại sau.',
  'admin.kiotviet.price.noti.update-price.success': 'Cập nhật điều chỉnh giá mới thành công!',
  'admin.kiotviet.price.noti.update-price.failure': 'Cập nhật điều chỉnh giá mới thất bại! Vui lòng thử lại sau.',
  'admin.kiotviet.price.noti.delete-price.success': 'Xóa điều chỉnh giá mới thành công',
  'admin.kiotviet.price.noti.delete-price.failure': 'Xóa điều chỉnh giá mới thất bại! Vui lòng thử lại sau.',
  'admin.kiotviet.price.modal.action.title.create': 'Thêm mới',
  'admin.kiotviet.price.modal.action.title.update': 'Cập nhật',
  'admin.kiotviet.price.modal.action.title.details': 'Chi tiết',
  'admin.kiotviet.price.modal.action.btn.create': 'Thêm',
  'admin.kiotviet.price.modal.action.btn.update': 'Cập nhật',
  'admin.kiotviet.price.modal.action.type.label': 'Điều chỉnh giá cho',
  'admin.kiotviet.price.modal.action.type.category.label': 'Danh mục',
  'admin.kiotviet.price.modal.action.type.product.label': 'Sản phẩm',
  'admin.kiotviet.price.modal.action.category.label': 'Chọn danh mục',
  'admin.kiotviet.price.modal.action.product.label': 'Chọn sản phẩm',
  'admin.kiotviet.price.modal.action.number.label': 'Nhập giá',
  'admin.kiotviet.price.modal.action.unit.label': 'Đơn vị',
  'admin.kiotviet.price.modal.action.unit.percent.label': 'Phần trăm',
  'admin.kiotviet.price.modal.action.unit.money.label': 'Tiền tệ',
  'admin.kiotviet.price.modal.delete.title': 'Xác nhận xóa giá điều chỉnh!',
  'admin.kiotviet.price.modal.delete.description': 'Bạn có chắc chắn muốn xóa giá điều chỉnh này?',
  'admin.kiotviet.price.error-msg.required': 'Vui lòng điền trường này!',
};

const vnAdminKiotvietLangLib = {
  ...price,
};

export default vnAdminKiotvietLangLib;
