// eslint-disable-next-line import/no-extraneous-dependencies
import { handleActions } from 'redux-actions';

import * as Actions from './actions';

const initialState = {
  // Get All Kiotviet Product
  isFirstGetAllKiotvietProducts: false,
  isGetAllKiotvietProductsRequest: false,
  isGetAllKiotvietProductsSuccess: false,
  isGetAllKiotvietProductsFailure: false,
  getAllKiotvietProductsState: {},
  // Create Kiotviet Product
  isCreateKiotvietProductRequest: false,
  isCreateKiotvietProductSuccess: false,
  isCreateKiotvietProductFailure: false,
  // Update Kiotviet Product
  isUpdateKiotvietProductRequest: false,
  isUpdateKiotvietProductSuccess: false,
  isUpdateKiotvietProductFailure: false,
  // Delete Kiotviet Product
  isDeleteKiotvietProductRequest: false,
  isDeleteKiotvietProductSuccess: false,
  isDeleteKiotvietProductFailure: false,
  // Local
  errorMessages: [],
};

const reducer = handleActions(
  {
    // #region : Get All Kiotviet Product
    [Actions.getAllKiotvietProductsRequest]: (state) => ({
      ...state,
      isGetAllKiotvietProductsRequest: true,
      isGetAllKiotvietProductsSuccess: false,
      isGetAllKiotvietProductsFailure: false,
    }),
    [Actions.getAllKiotvietProductsSuccess]: (state, { payload }) => ({
      ...state,
      isFirstGetAllKiotvietProducts: true,
      isGetAllKiotvietProductsRequest: false,
      isGetAllKiotvietProductsSuccess: true,
      isGetAllKiotvietProductsFailure: false,
      getAllKiotvietProductsState: payload,
    }),
    [Actions.getAllKiotvietProductsFailure]: (state, { payload }) => ({
      ...state,
      isGetAllKiotvietProductsRequest: false,
      isGetAllKiotvietProductsSuccess: false,
      isGetAllKiotvietProductsFailure: true,
      errorMessages: payload,
    }),
    // #endregion
    // #region : Create Kiotviet Product
    [Actions.createKiotvietProductRequest]: (state) => ({
      ...state,
      isCreateKiotvietProductRequest: true,
      isCreateKiotvietProductSuccess: false,
      isCreateKiotvietProductFailure: false,
    }),
    [Actions.createKiotvietProductSuccess]: (state) => ({
      ...state,
      isCreateKiotvietProductRequest: false,
      isCreateKiotvietProductSuccess: true,
      isCreateKiotvietProductFailure: false,
    }),
    [Actions.createKiotvietProductFailure]: (state, { payload }) => ({
      ...state,
      isCreateKiotvietProductRequest: false,
      isCreateKiotvietProductSuccess: false,
      isCreateKiotvietProductFailure: true,
      errorMessages: payload,
    }),
    [Actions.resetCreateKiotvietProduct]: (state) => ({
      ...state,
      isCreateKiotvietProductRequest: false,
      isCreateKiotvietProductSuccess: false,
      isCreateKiotvietProductFailure: false,
    }),
    // #endregion
    // #region : Update Kiotviet Product
    [Actions.updateKiotvietProductRequest]: (state) => ({
      ...state,
      isUpdateKiotvietProductRequest: true,
      isUpdateKiotvietProductSuccess: false,
      isUpdateKiotvietProductFailure: false,
    }),
    [Actions.updateKiotvietProductSuccess]: (state) => ({
      ...state,
      isUpdateKiotvietProductRequest: false,
      isUpdateKiotvietProductSuccess: true,
      isUpdateKiotvietProductFailure: false,
    }),
    [Actions.updateKiotvietProductFailure]: (state, { payload }) => ({
      ...state,
      isUpdateKiotvietProductRequest: false,
      isUpdateKiotvietProductSuccess: false,
      isUpdateKiotvietProductFailure: true,
      errorMessages: payload,
    }),
    [Actions.resetUpdateKiotvietProduct]: (state) => ({
      ...state,
      isUpdateKiotvietProductRequest: false,
      isUpdateKiotvietProductSuccess: false,
      isUpdateKiotvietProductFailure: false,
    }),
    // #endregion
    // #region : Delete Kiotviet Product
    [Actions.deleteKiotvietProductRequest]: (state) => ({
      ...state,
      isDeleteKiotvietProductRequest: true,
      isDeleteKiotvietProductSuccess: false,
      isDeleteKiotvietProductFailure: false,
    }),
    [Actions.deleteKiotvietProductSuccess]: (state) => ({
      ...state,
      isDeleteKiotvietProductRequest: false,
      isDeleteKiotvietProductSuccess: true,
      isDeleteKiotvietProductFailure: false,
    }),
    [Actions.deleteKiotvietProductFailure]: (state, { payload }) => ({
      ...state,
      isDeleteKiotvietProductRequest: false,
      isDeleteKiotvietProductSuccess: false,
      isDeleteKiotvietProductFailure: true,
      errorMessages: payload,
    }),
    [Actions.resetDeleteKiotvietProduct]: (state) => ({
      ...state,
      isDeleteKiotvietProductRequest: false,
      isDeleteKiotvietProductSuccess: false,
      isDeleteKiotvietProductFailure: false,
    }),
    // #endregion
    // #region : Local
    [Actions.resetKiotvietProductState]: () => initialState,
    // #endregion
  },
  initialState,
);

export default reducer;
