const user = {};

const member = {
  'admin.user.member.hetmet': 'Members - All users',
  'admin.user.member.breadcrumb.heading': 'All users',
  'admin.user.member.breadcrumb.parent': 'Members',
  'admin.user.member.btn.add-new': 'Add new users',
  'admin.user.member.noti.create-member.title.success': 'Add new members successful!',
  'admin.user.member.noti.create-member.title.failure': 'Adding new members failed!Please try again later.',
  'admin.user.member.noti.update-member.title.success': 'Successful members update!',
  'admin.user.member.noti.update-member.title.failure': 'Membership updates are unsuccessful!Please try again later.',
  'admin.user.member.noti.delete-member.title.success': 'Delete members successfully!',
  'admin.user.member.noti.delete-member.title.failure': 'Delete members unsuccessful!Please try again later.',
  'admin.user.member.noti.reset-password-member.title.success': 'Retite the password of the member successfully!',
  'admin.user.member.noti.reset-password-member.title.failure': "Returning the member's password failed!Please try again later.",
  'admin.user.member.modal.add.title': 'Add new users',
  'admin.user.member.modal.update.title': 'Update user information',
  'admin.user.member.modal.detail.title': 'User details',
  'admin.user.member.modal.delete.title': 'Confirm to delete members!',
  'admin.user.member.modal.delete.sub-title': 'You definitely want to delete this member?',
  'admin.user.member.modal.reset-password.title': 'Confirm reset password for this member!',
  'admin.user.member.modal.reset-password.label': 'Choose a new password:',
  'admin.user.member.modal.reset-password.input-1': 'Reset the default password',
  'admin.user.member.modal.reset-password.input-2': 'Send to Gmail',
  'admin.user.member.modal.reset-password.noti-1': 'A new password:',
};

const clients = {
  'admin.user.clients.hetmet': 'Members - All clients',
  'admin.user.clients.breadcrumb.heading': 'All clients',
  'admin.user.clients.breadcrumb.parent': 'Members',
  'admin.user.clients.btn.add-new': 'Add new client',
  'admin.user.clients.noti.create-clients.title.success': 'Add new client successful!',
  'admin.user.clients.noti.create-clients.title.failure': 'Adding new client failed! Please try again later.',
  'admin.user.clients.noti.update-clients.title.success': 'Successful client update!',
  'admin.user.clients.noti.update-clients.title.failure': 'Updates client is unsuccessful! Please try again later.',
  'admin.user.clients.noti.delete-clients.title.success': 'Delete client successfully!',
  'admin.user.clients.noti.delete-clients.title.failure': 'Delete client unsuccessful!Please try again later.',
  'admin.user.clients.noti.reset-password-clients.title.success': 'Retite the password of the client successfully!',
  'admin.user.clients.noti.reset-password-clients.title.failure': "Returning the client's password failed!Please try again later.",
  'admin.user.clients.modal.add.title': 'Add new client',
  'admin.user.clients.modal.update.title': 'Update client information',
  'admin.user.clients.modal.detail.title': 'Client details',
  'admin.user.clients.modal.delete.title': 'Confirm to delete client!',
  'admin.user.clients.modal.delete.sub-title': 'You definitely want to delete this client?',
  'admin.user.clients.modal.reset-password.title': 'Confirm reset password for this client!',
  'admin.user.clients.modal.reset-password.label': 'Choose a new password:',
  'admin.user.clients.modal.reset-password.input-1': 'Reset the default password',
  'admin.user.clients.modal.reset-password.input-2': 'Send to Gmail',
  'admin.user.clients.modal.reset-password.noti-1': 'A new password:',
};

const role = {
  'admin.user.role.hetmet': 'Members - Role',
  'admin.user.role.breadcrumb.heading': 'Role',
  'admin.user.role.breadcrumb.parent': 'Members',
  'admin.user.role.btn.add-new': 'Add a new role',
  'admin.user.role.noti.create-role.title.success': 'Add a new role successfully!',
  'admin.user.role.noti.create-role.title.failure': 'Adding a new failure!Please try again later.',
  'admin.user.role.noti.update-role.title.success': 'Update the role of success!',
  'admin.user.role.noti.update-role.title.failure': 'Update the role of unsuccessful!Please try again later.',
  'admin.user.role.noti.delete-role.title.success': 'Delete the role of success!',
  'admin.user.role.noti.delete-role.title.failure': 'Delete the role of unsuccessful!Please try again later.',
  'admin.user.role.modal.add.title': 'Add a new role',
  'admin.user.role.modal.update.title': 'Update the right for the role',
  'admin.user.role.modal.delete.title': 'Confirm the role of role!',
  'admin.user.role.modal.delete.sub-title': 'You definitely want to erase this role?',
};

const permissions = {
  'admin.user.permissions.hetmet': 'Members - Permissions',
  'admin.user.permissions.breadcrumb.heading': 'Permissions',
  'admin.user.permissions.breadcrumb.parent': 'Members',
  'admin.user.permissions.btn.add-new': 'Decentralization for users',
  'admin.user.permissions.noti.attach-role.title.success': 'Decentralize the user successfully!',
  'admin.user.permissions.noti.attach-role.title.failure': 'Decentralization for users failed!Please try again later.',
  'admin.user.permissions.noti.revoke-role.title.success': 'Delete the decentralization of users successfully!',
  'admin.user.permissions.noti.revoke-role.title.failure': 'Delete the decentralization of users failed!Please try again later.',
  'admin.user.permissions.modal.attach.title': 'Decentralization for users',
  'admin.user.permissions.modal.revoke.title': 'Confirm the deletion of user decentralization!',
  'admin.user.permissions.modal.revoke.sub-title': "You definitely want to erase this user's decentralization?",
};

const usAdminUserLangLib = {
  ...user,
  ...member,
  ...clients,
  ...role,
  ...permissions,
};

export default usAdminUserLangLib;
