// eslint-disable-next-line import/no-extraneous-dependencies
import { handleActions } from 'redux-actions';

import * as Actions from './actions';

const initialState = {
  isGetSettingsRequest: false,
  isGetSettingsSuccess: false,
  isGetSettingsFailure: false,
  getSettingsState: {},
  //
  isGetAllSettingsRequest: false,
  isGetAllSettingsSuccess: false,
  isGetAllSettingsFailure: false,
  getAllSettingsState: {},
  //
  isCreateSettingRequest: false,
  isCreateSettingSuccess: false,
  isCreateSettingFailure: false,
  //
  isSaveSettingRequest: false,
  isSaveSettingSuccess: false,
  isSaveSettingFailure: false,
  //
  isDeleteSettingRequest: false,
  isDeleteSettingSuccess: false,
  isDeleteSettingFailure: false,
  //
  isGetAllSettingRedirectRequest: false,
  isGetAllSettingRedirectSuccess: false,
  isGetAllSettingRedirectFailure: false,
  getAllSettingRedirectState: {},
  //
  isCreateSettingRedirectRequest: false,
  isCreateSettingRedirectSuccess: false,
  isCreateSettingRedirectFailure: false,
  //
  isUpdateSettingRedirectRequest: false,
  isUpdateSettingRedirectSuccess: false,
  isUpdateSettingRedirectFailure: false,
  //
  isDeleteSettingRedirectRequest: false,
  isDeleteSettingRedirectSuccess: false,
  isDeleteSettingRedirectFailure: false,
  //
  errorMessages: [],
};

const reducer = handleActions(
  {
    // #region : Get settings
    [Actions.getSettingsRequest]: (state) => ({
      ...state,
      isGetSettingsRequest: true,
      isGetSettingsSuccess: false,
      isGetSettingsFailure: false,
    }),
    [Actions.getSettingsSuccess]: (state, { payload }) => ({
      ...state,
      isGetSettingsRequest: false,
      isGetSettingsSuccess: true,
      isGetSettingsFailure: false,
      getSettingsState: payload,
    }),
    [Actions.getSettingsFailure]: (state, { payload }) => ({
      ...state,
      isGetSettingsRequest: false,
      isGetSettingsSuccess: false,
      isGetSettingsFailure: true,
      errorMessages: payload,
    }),
    // #endregion
    // #region : Get settings
    [Actions.getAllSettingsRequest]: (state) => ({
      ...state,
      isGetAllSettingsRequest: true,
      isGetAllSettingsSuccess: false,
      isGetAllSettingsFailure: false,
    }),
    [Actions.getAllSettingsSuccess]: (state, { payload }) => ({
      ...state,
      isGetAllSettingsRequest: false,
      isGetAllSettingsSuccess: true,
      isGetAllSettingsFailure: false,
      getAllSettingsState: payload,
    }),
    [Actions.getAllSettingsFailure]: (state, { payload }) => ({
      ...state,
      isGetAllSettingsRequest: false,
      isGetAllSettingsSuccess: false,
      isGetAllSettingsFailure: true,
      errorMessages: payload,
    }),
    // #endregion
    // #region : Create setting
    [Actions.createSettingRequest]: (state) => ({
      ...state,
      isCreateSettingRequest: true,
      isCreateSettingSuccess: false,
      isCreateSettingFailure: false,
    }),
    [Actions.createSettingSuccess]: (state) => ({
      ...state,
      isCreateSettingRequest: false,
      isCreateSettingSuccess: true,
      isCreateSettingFailure: false,
    }),
    [Actions.createSettingFailure]: (state, { payload }) => ({
      ...state,
      isCreateSettingRequest: false,
      isCreateSettingSuccess: false,
      isCreateSettingFailure: true,
      errorMessages: payload,
    }),
    [Actions.resetCreateSettingState]: (state) => ({
      ...state,
      isCreateSettingRequest: false,
      isCreateSettingSuccess: false,
      isCreateSettingFailure: false,
      errorMessages: [],
    }),
    // #endregion
    // #region : Save setting
    [Actions.saveSettingRequest]: (state) => ({
      ...state,
      isSaveSettingRequest: true,
      isSaveSettingSuccess: false,
      isSaveSettingFailure: false,
    }),
    [Actions.saveSettingSuccess]: (state) => ({
      ...state,
      isSaveSettingRequest: false,
      isSaveSettingSuccess: true,
      isSaveSettingFailure: false,
    }),
    [Actions.saveSettingFailure]: (state, { payload }) => ({
      ...state,
      isSaveSettingRequest: false,
      isSaveSettingSuccess: false,
      isSaveSettingFailure: true,
      errorMessages: payload,
    }),
    [Actions.resetSaveSettingState]: (state) => ({
      ...state,
      isSaveSettingRequest: false,
      isSaveSettingSuccess: false,
      isSaveSettingFailure: false,
      errorMessages: [],
    }),
    // #endregion
    // #region : Delete setting
    [Actions.deleteSettingRequest]: (state) => ({
      ...state,
      isDeleteSettingRequest: true,
      isDeleteSettingSuccess: false,
      isDeleteSettingFailure: false,
    }),
    [Actions.deleteSettingSuccess]: (state) => ({
      ...state,
      isDeleteSettingRequest: false,
      isDeleteSettingSuccess: true,
      isDeleteSettingFailure: false,
    }),
    [Actions.deleteSettingFailure]: (state, { payload }) => ({
      ...state,
      isDeleteSettingRequest: false,
      isDeleteSettingSuccess: false,
      isDeleteSettingFailure: true,
      errorMessages: payload,
    }),
    [Actions.resetDeleteSettingState]: (state) => ({
      ...state,
      isDeleteSettingRequest: false,
      isDeleteSettingSuccess: false,
      isDeleteSettingFailure: false,
      errorMessages: [],
    }),
    // #endregion

    // #region get all setting redirect
    [Actions.getAllSettingRedirectRequest]: (state) => ({
      ...state,
      isGetAllSettingRedirectRequest: true,
      isGetAllSettingRedirectSuccess: false,
      isGetAllSettingRedirectFailure: false,
    }),
    [Actions.getAllSettingRedirectSuccess]: (state, { payload }) => ({
      ...state,
      isGetAllSettingRedirectRequest: false,
      isGetAllSettingRedirectSuccess: true,
      isGetAllSettingRedirectFailure: false,
      getAllSettingRedirectState: payload,
    }),
    [Actions.getAllSettingRedirectFailure]: (state, { payload }) => ({
      ...state,
      isGetAllSettingRedirectRequest: false,
      isGetAllSettingRedirectSuccess: false,
      isGetAllSettingRedirectFailure: true,
      errorMessages: payload,
    }),
    // #endregion

    // #region create redirect
    [Actions.createSettingRedirectRequest]: (state) => ({
      ...state,
      isCreateSettingRedirectRequest: true,
      isCreateSettingRedirectSuccess: false,
      isCreateSettingRedirectFailure: false,
    }),
    [Actions.createSettingRedirectSuccess]: (state) => ({
      ...state,
      isCreateSettingRedirectRequest: false,
      isCreateSettingRedirectSuccess: true,
      isCreateSettingRedirectFailure: false,
    }),
    [Actions.createSettingRedirectFailure]: (state, { payload }) => ({
      ...state,
      isCreateSettingRedirectRequest: false,
      isCreateSettingRedirectSuccess: false,
      isCreateSettingRedirectFailure: true,
      errorMessages: payload,
    }),
    [Actions.resetCreateSettingRedirectState]: (state) => ({
      ...state,
      isCreateSettingRedirectRequest: false,
      isCreateSettingRedirectSuccess: false,
      isCreateSettingRedirectFailure: false,
      errorMessages: [],
    }),
    // #endregion
    // #region update redirect
    [Actions.updateSettingRedirectRequest]: (state) => ({
      ...state,
      isUpdateSettingRedirectRequest: true,
      isUpdateSettingRedirectSuccess: false,
      isUpdateSettingRedirectFailure: false,
    }),
    [Actions.updateSettingRedirectSuccess]: (state) => ({
      ...state,
      isUpdateSettingRedirectRequest: false,
      isUpdateSettingRedirectSuccess: true,
      isUpdateSettingRedirectFailure: false,
    }),
    [Actions.updateSettingRedirectFailure]: (state, { payload }) => ({
      ...state,
      isUpdateSettingRedirectRequest: false,
      isUpdateSettingRedirectSuccess: false,
      isUpdateSettingRedirectFailure: true,
      errorMessages: payload,
    }),
    [Actions.resetUpdateSettingRedirectState]: (state) => ({
      ...state,
      isUpdateSettingRedirectRequest: false,
      isUpdateSettingRedirectSuccess: false,
      isUpdateSettingRedirectFailure: false,
      errorMessages: [],
    }),
    // #endregion
    // #region delete redirect
    [Actions.deleteSettingRedirectRequest]: (state) => ({
      ...state,
      isDeleteSettingRedirectRequest: true,
      isDeleteSettingRedirectSuccess: false,
      isDeleteSettingRedirectFailure: false,
    }),
    [Actions.deleteSettingRedirectSuccess]: (state) => ({
      ...state,
      isDeleteSettingRedirectRequest: false,
      isDeleteSettingRedirectSuccess: true,
      isDeleteSettingRedirectFailure: false,
    }),
    [Actions.deleteSettingRedirectFailure]: (state, { payload }) => ({
      ...state,
      isDeleteSettingRedirectRequest: false,
      isDeleteSettingRedirectSuccess: false,
      isDeleteSettingRedirectFailure: true,
      errorMessages: payload,
    }),
    [Actions.resetDeleteSettingRedirectState]: (state) => ({
      ...state,
      isDeleteSettingRedirectRequest: false,
      isDeleteSettingRedirectSuccess: false,
      isDeleteSettingRedirectFailure: false,
      errorMessages: [],
    }),
    // #endregion

    // #region : Local
    [Actions.resetSettingsState]: () => initialState,
    // #endregion
  },
  initialState,
);

export default reducer;
